import React, { Component } from 'react';
import PageWrapper from '../wrappers/Page';

import { TABLE_CLASS, FRONTEND_URL, BACKEND_URL } from '../util/Constants';
import { Switch, Route, Redirect } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportTypes: {
        "COTS": "status",
        "Hospital Admin": "status",
        "Hospital User": "status",
        "ED Admin": "status",
        "FAO": "eptp"
      }
    }
  }
  render() {
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    var week_ago = new Date();
    week_ago.setDate(today.getDate() - 7);
    var month_ago = new Date();
    month_ago.setMonth(today.getMonth() - 1);
    var year_ago = new Date();
    year_ago.setYear(today.getFullYear() - 1);
    return (
      <div>
        <Switch>
          <Route exact path='/reports/' render={(props) => { return (<Report {...this.props} reportType={this.state.reportTypes[this.props.userType]} />) }} />
        </Switch>
      </div>
    )
  }

}

class Report extends Component {
  constructor(props) {
    super(props);
    var today = new Date();

    let filteredEdIds = [];
    let userEds = [];
    if (this.props.userType === 'COTS') {
        userEds = this.props.eds;
    } else if (this.props.userED && this.props.userED != 'n/a' && this.props.eds) {
        userEds = this.props.eds.filter(ed => ed.name == this.props.userED)
    } else if (this.props.userSystem && this.props.eds) {
        userEds = this.props.eds.filter(ed => ed.system.name == this.props.userSystem);
    }

    this.state = {
      filteredEdIds: filteredEdIds,
      userEds: userEds,
      reportType: "",
      reportRange: "custom",
      reportTypeCommas: {
        'status': ",,,,,,,\n",
        'census': ",,\n",
        'eptp': ",,,,,,,,,,,,\n",
        'eptpBasic': ",,,\n",
        'diversion': ",,,,,,,\n",
        'Facility Alert': ",,,\n",
        'EPTP Defer': ",,,\n",
        'EPTP DeferBasic': ",,,\n",
      },
      startDate: today,
      endDate: today,
      filename: (type, start, end) => {
        if (!start && !end) {
          return capitalize(type) + "_Historical.csv"
        }
        else if (!start && end) {
          return capitalize(type) + "_Historical_Before_" + formatDate(end) + ".csv"
        }
        return capitalize(type) + "_" + formatDate(start) + "_" + formatDate(end) + ".csv"
      },
      title: (type, start, end) => {
        if (!start && !end) {
          return capitalize(type) + " Historical"
        }
        else if (!start && end) {
          return capitalize(type) + " Historical Before " + formatDate(end)
        }
        return capitalize(type) + " History " + formatDate(start) + "-" + formatDate(end)
      },
      EDStatusReportURL: (start, end, reportType, userType, userED, userSystem, filteredEdIds) => {
        return (BACKEND_URL + "/api/reports/" + reportType + "/?start=" + formatDate(start) + "&end=" + formatDate(end) + "&userType=" + userType + "&userED=" + userED + "&userSystem=" + userSystem + "&filteredEdIds=" + filteredEdIds)
      },
      clickCount: 0,
      download: () => {
        if (this.state.reportType === '') {
          window.alert("Select Report Type")
          return null;

        }
        if (this.state.clickCount === 1) {
          window.alert("double click forbidden")
          return null;
        }
        this.setState({ clickCount: 1 })
        if ((this.state.startDate && !this.state.endDate)) {
          window.alert("Error: Select an end date. Leave both dates blank for a full historical report.");
          return null;
        }
        let headers = new Headers();
        var endpoint = this.state.EDStatusReportURL(this.state.startDate, this.state.endDate, this.state.reportType, this.props.userType, this.props.userED, this.props.userSystem, this.state.filteredEdIds);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', FRONTEND_URL + '/reports');
        //set the fetch configurations
        const conf = {
          method: 'get',
          headers: headers,
        };
        //fetch the resource from the backend and set the status to the response data
        return fetch(endpoint, conf).then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error("report data load failure");
        }).then(jsonData => {
          if (jsonData.length === 0) { return null; }

          var csv = convertArrayOfObjectsToCSV({
            data: jsonData
          });

          // Prepend report title and column label row
          csv = this.state.title(this.state.reportType, this.state.startDate, this.state.endDate) + this.state.reportTypeCommas[this.state.reportType + (this.props.userType === 'ED Admin' || this.props.userType === 'Hospital Admin' ? 'Basic' : "")] + csv;

          return new Blob([csv], { type: 'text/csv' });
        }).then((blob) => {
          var filename = this.state.filename(this.state.reportType, this.state.startDate, this.state.endDate).replace(/\//g, '_');
          if (blob != null) {
            if (window.navigator.msSaveBlob) {
              // Special handling for Internet Explorer
              window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
              // Handling for default browser
              var link = document.createElement('a');
              link.setAttribute('download', filename);
              link.setAttribute('href', window.URL.createObjectURL(blob, { type: 'text/csv' }));
              link.click()
            }
          }
          else {
            window.alert("No data for selected range.")
          }
          this.setState({ clickCount: 0 });
        }).catch((error) => {
          alert('Sorry, an error occurred');
          console.log("We got: ", error);
          this.setState({ clickCount: 0 });
        })

      },
      reportTypeOptions: {
        "FAO": ['', 'eptp', 'EPTP Defer'],
        "Hospital Admin": ['', 'status', 'diversion', 'Filtered Diversion', 'eptp', 'Facility Alert' ],
        "ED Admin": ['', 'status', 'diversion', 'Filtered Diversion', 'eptp', 'Facility Alert'],
        "COTS": ['', 'status', 'diversion', 'Filtered Diversion', 'eptp', 'Facility Alert', 'EPTP Defer'],
        "EMS": [],
        "Hospital User": [],
        undefined: [],
      }
    };
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.selectReportTypeOnChange = this.selectReportTypeOnChange.bind(this);
  }

  onChangeFilteredEds = (filteredEdIds) => {
    this.setState({ filteredEdIds: filteredEdIds })
  }
  selectReportTypeOnChange(e) {
    this.setState({ reportType: e.target.value })
  }
  handleStartChange(date) {
    this.setState({
      startDate: date
    });
  }
  handleEndChange(date) {
    this.setState({
      endDate: date
    });
  }

  render() {
    if (this.props.userType === 'EMS' || this.props.userType === 'Hospital User' || this.props.userType === 'MECC') {
      return (<Redirect to='/'></Redirect>)
    }
    var today = new Date()
    var week_ago = new Date()
    week_ago.setDate(today.getDate() - 7)
    var month_ago = new Date()
    month_ago.setMonth(today.getMonth() - 1)
    var three_months_ago = new Date()
    three_months_ago.setMonth(today.getMonth() - 3)
    var six_months_ago = new Date()
    six_months_ago.setMonth(today.getMonth() - 6)
    var start = { 'custom': today, 'week': week_ago, "month": month_ago, "3month": three_months_ago, "6month": six_months_ago }
    return (
      <div className="my-5 d-flex justify-content-center align-items-center row">
        <div className="x-4 my-5 box-shadow">
          <div className="my-5 px-5" >
            <span className="mx-2"><b>Report Type:</b></span>
            <Select userType={this.props.userType} onChange={this.selectReportTypeOnChange} options={this.state.reportTypeOptions[this.props.userType]} />
            <span className="mx-2"><b>Report Range:</b></span>
            <select className={" form-control mb-4"} id="DateRange" onChange={(e) => { this.setState({ reportRange: e.target.value, startDate: start[e.target.value], endDate: today }) }}>
              <option value="custom">Custom</option>
              <option value="week">Past Week</option>
              <option value="month">Past Month</option>
              <option value="3month">Past 3 Months</option>
              <option value="6month">Past 6 Months</option>
            </select>


            <div className="mx-3"><b className="mx-2">Start:</b><DatePicker className={" form-control mb-4"} selected={this.state.startDate} onChange={this.handleStartChange} /></div>
            <div className="mx-3"><b className="mx-2">End:</b><DatePicker className={" form-control mb-4"} selected={this.state.endDate} onChange={this.handleEndChange} /></div>
            { this.state.reportType.indexOf('Filtered') != -1 && <EDFilter eds={this.state.userEds} onChangeFilteredEds={(val) => this.onChangeFilteredEds(val)} filteredEdIds={this.state.filteredEdIds} /> }
            <div>
              <button className="btn btn-primary" onClick={this.state.download}>Export </button>
              <img alt="spinner" style={{ display: this.state.clickCount === 0 ? "none" : "inline" }} className="spinner" src={require('../spinner.png')}></img>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

class Select extends Component {
  render() {
    return (
      <select className={" form-control mb-4"} id="reportType" onChange={this.props.onChange}>
        {this.props.options.map((op) => { return (<option key={op} value={op}>{capitalize(op)}</option>) })}
      </select>
    );
  }
}
function formatDate(dateobj) {
  if (dateobj) {
    var curYear = dateobj.getFullYear();
    var curMonth = dateobj.getMonth() + 1 < 10 ? "0" + (dateobj.getMonth() + 1) : dateobj.getMonth() + 1;
    var curDate = dateobj.getDate() < 10 ? "0" + dateobj.getDate() : dateobj.getDate();

    return (curMonth + "/" + curDate + "/" + curYear);
  }
  return ""
}

function convertArrayOfObjectsToCSV(args) {
  var result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function (item) {
    ctr = 0;
    keys.forEach(function (key) {

      if (ctr > 0) result += columnDelimiter;
      result += item[key]
      ctr++;
    });
    result += lineDelimiter;

  });
  return result;
}
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  if (s === 'eptp') return 'EPTP'
  return s.charAt(0).toUpperCase() + s.slice(1)
}

class EDFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'isCollapsed': false
        }
    }

    onChangeFilter(id) {
        let ids = [];
        if (this.props.filteredEdIds.includes(id)) {
            ids = this.props.filteredEdIds.filter(fId => fId != id);
        } else {
            ids = [...this.props.filteredEdIds, id];
        }
        this.props.onChangeFilteredEds(ids);
    }

    getEdNamesForIds() {
        const eds = this.props.eds.filter(ed => this.props.filteredEdIds.includes(ed.id));

        if (eds.length) {
            eds.sort(ed => (a,b) => a.name.localeCompare(b.name))
            return <>Report will include the following EDs:<br/>{eds.map(ed => ed.name).join(', ')}</>
        } else {
            return 'No filter applied. All EDs listed in the table will be included in the report.';
        }
    }

    onToggleCollapse() {
        this.setState({
            'isCollapsed': !this.state.isCollapsed,
        })
    }

    render() {
      let cols = [[],[],[]];
      let edsPerCol = 0;
      this.props.eds.sort((a,b) => a.name.localeCompare(b.name))
      if (this.props.eds) {
          const numEds = this.props.eds.length;
          edsPerCol = Math.ceil(numEds / 3);
          cols[0] = this.props.eds.slice(0, edsPerCol);
          cols[1] = this.props.eds.slice(edsPerCol, 2*edsPerCol);
          cols[2] = this.props.eds.slice(2*edsPerCol);
      }

      return (
        <table className={`contact-list-filter-panel ${TABLE_CLASS}`}>
            <thead className="cotsBlue"><tr><th colspan="2">
                Filter by ED
                <button class="sort-button" onClick={() => this.onToggleCollapse()}>{this.state.isCollapsed ? 'Expand' : 'Collapse'}</button>
                <button class="sort-button" onClick={() => this.props.onChangeFilteredEds([])}>Clear</button>
            </th></tr></thead>
            <tbody style={{ textAlign: 'left' }} className={ this.state.isCollapsed ? 'collapsed':'expanded' }>
                <table class="w100">
                    <tbody>
                        {[...Array(edsPerCol).keys()].map(i => <tr>
                            {[0,1,2].map(j => 
                            i < cols[j].length && <td>
                                <input id={'filter-checkbox-'+i+'-'+j} className="filter-checkbox" type="checkbox" checked={this.props.filteredEdIds.includes(cols[j][i].id)} onChange={() => this.onChangeFilter(cols[j][i].id)}/>
                                <label for={'filter-checkbox-'+i+'-'+j}>{cols[j][i].name}</label>
                            </td>)}
                        </tr>)}
                    </tbody>
                </table>
            </tbody>
            <tfoot>
                <tr className="cotsBlue text-left p-0">
                    <td colspan="3">
                        {this.getEdNamesForIds()}
                    </td>
                </tr>
            </tfoot>
        </table>
      )
    }
}


// renders the skeleton for each page,
//the first set of parameters are (heading_value, component_id)
//the second set of parameters is just (Component) where Component is the component you wish to render in the page skelton
export default PageWrapper('Reports', 'Reports')(Reports);
